import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Pricing from "../components/pricing"
import Divider1 from "../../assets/images/dividers/divider-1.svg"
import Divider2 from "../../assets/images/dividers/divider-2.svg"
import Divider3 from "../../assets/images/dividers/divider-3.svg"
import DecoBlob2 from "../../assets/images/decorations/deco-blob-2.svg"
import WorkshopImage from "../../assets/images/workshop.jpg"
import Plus from "../../assets/images/icons/interface/plus.svg"
import {
  InvalidEmailMessage,
  SuccessForm,
  FailureForm,
} from "../components/utils"
import { postData } from "../components/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import * as data from "../../content/courses/kubernetes-for-developers/kubernetes-for-developers.json"
import PageLogo from "../../assets/images/og_image.png"

const Form = ({ slug }) => {
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = data => {
    const body = {
      email: data.email,
      name: data.name,
      workshop: data.workshop,
    }

    postData({ body })
      .then(resp => {
        if (resp.status / 100 > 3) {
          throw new Error(
            "Something did not work as expected, please try again."
          )
        }
        return resp.json()
      })
      .then(
        body => {
          setSuccess(true)
          setTimeout(() => setSuccess(false), 5000)
        },
        error => {
          setFailure({ message: error.message })
          setTimeout(() => setFailure(false), 5000)
        }
      )
  }

  return (
    <div className="col">
      <div className="row justify-content-center">
        <div className="col-xl-9 col-md-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="name">Your Name</label>
              <input
                name="name"
                type="text"
                label="name"
                className="form-control form-control-lg"
                placeholder="Type your name"
                ref={register({
                  required: true,
                })}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input
                name="email"
                label="email"
                className="form-control form-control-lg"
                placeholder="you@wanna-learn-it.com"
                ref={register({
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              <input
                type="hidden"
                value={slug}
                name="workshop"
                ref={register({
                  required: true,
                })}
              />
            </div>

            <input
              type="text"
              readOnly={true}
              className="d-none"
              name="b_860653c89e9099a93a9fd055e_0fb3a274eb"
              tabIndex="-1"
              value=""
            />

            <div className="form-group text-center text-light">
              <button
                className="btn btn-lg btn-primary btn-block mb-2"
                type="submit"
                name="subscribe"
              >
                Get two free preview lessons
              </button>
              {/* errors will return when field validation fails  */}
              <FailureForm
                show={errors.name}
                message={"Please don't forget to type your name"}
              />
              <FailureForm show={errors.email} message={InvalidEmailMessage} />
              <SuccessForm show={success} />
              <FailureForm show={failure} message={failure.message} />
              <small>NO SPAM and you can opt-out whenever you want.</small>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const FaqCollections = ({ faqs }) => {
  const [ShowFaqs, setShowFaqs] = useState(faqs.map(() => false))
  const expandFaq = index => {
    const NewArray = [...ShowFaqs]
    NewArray[index] = !NewArray[index]
    setShowFaqs(NewArray)
  }

  const PlusButton = ({ index }) => {
    return (
      <Plus
        className="icon"
        alt="plus interface icon"
        onClick={() => expandFaq(index)}
      />
    )
  }

  return faqs.map((faq, index) => (
    <div className="card mb-2 card-sm card-body hover-shadow-sm" key={index}>
      <div
        data-target={"panel-" + index}
        className="accordion-panel-title collapsed"
        data-toggle="collapse"
        role="button"
        aria-expanded={ShowFaqs[index]}
        aria-controls={"panel-" + index}
      >
        <span
          className="h6 mb-0"
          onClick={() => expandFaq(index)}
          onKeyPress={() => expandFaq(index)}
          role="button"
        >
          {faq.question}
        </span>
        <PlusButton index={index} />
      </div>
      {ShowFaqs[index] && (
        <div className="collapse show">
          <div className="pt-3">
            <p className="mb-0">{faq.answer}</p>
          </div>
        </div>
      )}
    </div>
  ))
}

const Faq = ({ faqs }) => (
  <section className="has-divider bg-primary-2-alt">
    <div className="divider flip-y">
      <Divider2 alt="graphical divider" />
    </div>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-9">
          <h3 className="h2">Frequently Asked Questions</h3>
          <div className="my-4">
            <FaqCollections faqs={faqs} />
          </div>
        </div>
      </div>
    </div>
  </section>
)

const Header = ({ cta, subtitle, slug }) => {
  // Random decoration
  const Decoration = () => {
    return (
      <div className="decoration-wrapper d-none d-md-block">
        <div className="decoration top right scale-4">
          <DecoBlob2 className="bg-primary-2" alt="deco-blob-2 decoration" />
        </div>
      </div>
    )
  }

  return (
    <section className="bg-primary-3 has-divider o-hidden">
      <div className="container layer-2">
        <div className="row justify-content-center pt-lg-5">
          <div className="col-xl-5 col-lg-6 col-md-7 text-center text-light text-lg-left mb-5 mb-lg-0">
            <div className="d-flex mb-4">
              <span className="badge badge-primary mr-2">Now available</span>
            </div>
            <h1 className="display-3">{cta}</h1>
            <div className="my-4">
              <p className="lead">{subtitle}</p>
            </div>
          </div>
          <Form slug={slug} />
        </div>
      </div>
      <Decoration />
      <div className="divider">
        <Divider1 alt="divider graphic" />
      </div>
    </section>
  )
}

const Paragraphs = ({ paragraphs }) => {
  return paragraphs.map((paragraph, index) => {
    return (
      // <p className="lead" key={index} dangerouslySetInnerHTML={{__html: marked(paragraph, {sanitize: true})}} />
      <p
        className="lead"
        key={index}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    )
  })
}

const LearningParagraphs = ({ paragraphs }) => {
  return paragraphs.map((paragraph, index) => {
    return (
      <p className="lead text-left" key={index}>
        <FontAwesomeIcon className="text-success mr-2" icon={faCheck} />
        {paragraph}
      </p>
    )
  })
}

const Learn = ({ learnings }) => (
  <section className="bg-primary-3 pt-0 has-divider">
    <div className="container">
      <div className="row justify-content-center text-center text-light">
        <div className="col-xl-8 col-lg-9">
          <h2 className="display-4 mx-xl-6">What you'll learn</h2>
          <div className="px-5">
            <LearningParagraphs paragraphs={learnings} />
          </div>
        </div>
      </div>
    </div>
    <div className="divider bg-primary-1 flip-x">
      <Divider3 alt="graphical divider" />
    </div>
  </section>
)

const Program = ({ program }) => {
  let counter = 0
  // Create all the chapters inside a course
  const Chapters = ({ parts }) => {
    const Points = ({ points }) => {
      const pointsList = points.map((point, index) => {
        counter = counter + 1
        return (
          <li key={index}>
            {counter}. {point}
          </li>
        )
      })
      return <ul className="list-unstyled mb-0">{pointsList}</ul>
    }

    // Create a Chapter
    const partsList = parts.map((part, index) => {
      let className =
        "card card-body justify-content-between shadow-3d rotate-left"
      if (index === 3) {
        className = className + " " + "bg-primary-3 text-light"
      }
      return (
        <>
          <div className="col-md-8 col-lg-8 d-flex" key={index}>
            <div className={className}>
              <div>
                <h3>{part.title}</h3>
                <p>{part.description}</p>
                <Points points={part.chapters} />
              </div>
            </div>
          </div>
        </>
      )
    })

    return partsList
  }

  return (
    <section>
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-xl-7 col-lg-7">
            <h2 className="h1 mx-xl-6">The lessons</h2>
            <h2 className="h3 mx-xl-8">
              4 modules and 30 lessons to bring you up to speed with Kubernetes
            </h2>
          </div>
        </div>

        <div className="row justify-content-center mb-5">
          <Chapters parts={program} />
        </div>
      </div>
    </section>
  )
}

const About = () => (
  <section className="has-divider">
    <div className="container">
      <div className="row align-items-center justify-content-around">
        <div className="col-md-3">
          <img
            src={WorkshopImage}
            alt="Christian Barra"
            className="rounded-circle layer-2"
          />
        </div>
        <div className="col-xl-5 col-md-6">
          <h3 className="h1">About the coach</h3>
          <p className="lead">
            Hey there! My name is{" "}
            <a href="https://twitter.com/christianbarra">Christian</a>. I'm a
            software engineer, tech lead and{" "}
            <a href="https://www.youracclaim.com/badges/c6bbd94a-ba36-4849-b503-53378664385d">
              Certified Kubernetes Application Developer
            </a>{" "}
            living in Berlin.
          </p>
          <p className="lead">
            I'm a proactive member in the tech community,{" "}
            <a href="https://www.python.org/psf/fellows/">
              Python Software Foundation Fellow
            </a>
            , open-source contributor, international speaker and conference
            organiser.
          </p>
        </div>
      </div>
    </div>
  </section>
)

const Buy = ({ reasons, cta_reasons }) => (
  <section className="has-divider">
    <div className="container">
      <div className="row justify-content-center text-center mb-5">
        <div className="col-xl-8 col-lg-9">
          <h2 className="display-4 mx-xl-8">{cta_reasons}</h2>
          <Paragraphs paragraphs={reasons} />
        </div>
      </div>
    </div>
    <div className="divider flip-y bg-primary-3">
      <Divider3 alt="graphical divider" />
    </div>
  </section>
)

const HomePage = () => {
  const useSiteMetadata = () => {
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
              siteUrl
              description
            }
          }
        }
      `
    )
    return site.siteMetadata
  }
  const { siteUrl, title } = useSiteMetadata()
  const { cta, cta_reason, subtitle } = data

  return (
    <>
      <SEO
        title={title}
        meta={[
          {
            property: `og:image`,
            content: siteUrl + PageLogo,
          },
          {
            name: `twitter:image`,
            content: siteUrl + PageLogo,
          },
        ]}
      />
      <Header cta={cta} subtitle={subtitle} slug="k8sfordevelopers" />
      <Buy reasons={data.reasons} cta_reasons={cta_reason} />
      <Learn learnings={data.learnings} />
      <Program program={data.program} />
      {/*
      <MDXProvider>
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider> */}
      <Pricing />
      <About />
      <Faq faqs={data.faqs} />
    </>
  )
}

export default HomePage
