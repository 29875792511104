const APISubscriberEndpoint = `https://api-pybootcamp-com-u5qt3xhtdq-ew.a.run.app/v1/subscriber`

const postData = ({ url = APISubscriberEndpoint, body }) => {
  return fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
}
export { postData }
