import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Iframe from "react-iframe"

const GumRoadModal = ({ show, onHide, url }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Iframe
        url={url}
        width="100%"
        height="850px"
        id="myId"
        className="border-0"
        display="initial"
        position="relative"
      />
    </Modal>
  )
}

const PricingElement = ({ text }) => {
  return (
    <li className="py-1">
      <FontAwesomeIcon className="text-success" icon={faCheckCircle} /> {text}
    </li>
  )
}

const Pricing = () => {
  const [modalShow, setModalShow] = useState(false)
  const [modalUrl, setModalUrl] = useState("")

  const onModalButtonClick = url => {
    setModalUrl(url)
    setModalShow(true)
  }

  return (
    <section>
      <div className="container">
        <div className="row justify-content-md-center">
          <h2 className="display-4 mx-xl-6">Get Kubernetes for Developers</h2>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-6 ">
            <div className="card card-body align-items-center shadow-3d">
              <div className="pt-md-2">
                <h4>
                  Professional{" "}
                  <span role="img" aria-label="rocket">
                    🚀
                  </span>
                </h4>
              </div>
              <div className="d-flex align-items-start pb-md-2">
                <span className="h3">$</span>
                <span className="display-4 mb-0">
                  99
                  <span className="text-small text-muted">USD</span>
                </span>
              </div>
              <ul className="list-unstyled my-2 my-md-4">
                <PricingElement text="Access to all 30 lessons" />
                <PricingElement text="Full source code for all lessons" />
                <PricingElement text="Over 4 hours and 30 minutes of video content" />
                <PricingElement text="Includes Kubernetes real-world scenarios: 8 additional lessons on CI/CD, migrations, deployments, operators and terraform" />
                <PricingElement text="Download videos" />
              </ul>
              <Button
                variant="primary"
                onClick={() =>
                  onModalButtonClick("https://gumroad.com/l/Xaeqs/8vbh7fn")
                }
              >
                Buy now
              </Button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card card-body align-items-center ">
              <div className="pt-md-2">
                <h4>
                  Essentials{" "}
                  <span role="img" aria-label="saving">
                    💰
                  </span>
                </h4>
              </div>
              <div className="d-flex align-items-start pb-md-2">
                <span className="h3">$</span>
                <span className="display-4 mb-0">
                  59
                  <span className="text-small text-muted">USD</span>
                </span>
              </div>
              <ul className="list-unstyled my-2 my-md-4">
                <PricingElement text="Access to the first 22 lessons" />
                <PricingElement text="Just about 3 hours of video content" />
                <PricingElement text="Download videos" />
              </ul>
              <Button
                variant="primary"
                onClick={() =>
                  onModalButtonClick("https://gumroad.com/l/EdxTuO/olwfp2z")
                }
              >
                Buy now
              </Button>
              <GumRoadModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                url={modalUrl}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pricing
