import React, { useState } from "react"
import { Link } from "gatsby"
import { useForm } from "react-hook-form"
import Divider3 from "../../assets/images/dividers/divider-3.svg"
import Divider1 from "../../assets/images/dividers/divider-1.svg"
import Spotify from "../../assets/images/logos/spotify.svg"
import Slack from "../../assets/images/logos/slack.svg"
import { postData } from "./api"

const InvalidEmailMessage = () => {
  return (
    <>
      That does not look like a valid email{" "}
      <span role="img" aria-label="thinking">
        🤔
      </span>
    </>
  )
}
const SuccessForm = ({ show }) => (
  <>
    {show && (
      <div className="alert alert-success" role="alert">
        Thanks, check your email{" "}
        <span role="img" aria-label="thumbs up">
          👍
        </span>
      </div>
    )}
  </>
)
const FailureForm = ({ show, message }) => {
  if (!show) {
    return null
  }
  if (typeof message === "function") {
    const Message = message
    return (
      <div className="alert alert-danger" role="alert">
        <Message />
      </div>
    )
  }
  return (
    <div className="alert alert-danger" role="alert">
      {message}
    </div>
  )
}

const Subscribe = ({ white_bg, grey_bg, divider, message }) => {
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)

  const cta = message ? message : `Boost your Python and DevOps skills`

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = data => {
    const body = {
      email: data.email,
    }

    postData({ body })
      .then(resp => {
        if (resp.status / 100 > 3) {
          throw new Error(
            "Something did not work as expected, please try again."
          )
        }
        return resp.json()
      })
      .then(
        body => {
          setSuccess(true)
          setTimeout(() => setSuccess(false), 5000)
        },
        error => {
          setFailure({ message: error.message })
          setTimeout(() => setFailure(false), 5000)
        }
      )
  }

  let colorValue = white_bg ? "bg-primary-alt-2" : "bg-primary-2 text-light"
  colorValue = grey_bg ? "bg-primary-2-alt" : colorValue

  const Divider = () => {
    if (divider === "1") {
      return (
        <div className="divider flip-y">
          <Divider1 alt="graphical divider" />
        </div>
      )
    } else {
      return (
        <div className="divider flip-y">
          <Divider3 alt="graphical divider" />
        </div>
      )
    }
  }

  return (
    <section className={colorValue + " has-divider"}>
      <Divider />
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-xl-7 col-lg-7 col-md-10">
            <h3 className="h2 mb-5">{cta}</h3>
            <p className="lead mb-5">
              Get great content on Python, DevOps and cloud architecture.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-sm-flex flex-column flex-sm-row mb-3 justify-content-center">
                <input
                  name="email"
                  placeholder="Email Address"
                  aria-label="Your email address"
                  className="mr-sm-1 mb-2 mb-sm-0 form-control form-control-lg"
                  ref={register({
                    required: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                <input
                  name="Name"
                  placeholder="Name"
                  label="name"
                  className="d-none"
                  ref={register}
                />
                <button
                  type="submit"
                  className="ml-sm-1 btn btn-lg btn-primary btn-loading"
                >
                  Subscribe
                </button>
              </div>
              <div>
                {/* errors will return when field validation fails  */}
                <FailureForm
                  show={errors.email}
                  message={InvalidEmailMessage}
                />
                <SuccessForm show={success} />
                <FailureForm show={failure.email} message={success.message} />
              </div>
            </form>

            <div className="text-small text-muted">
              You don't like spam? Neither do I!
              <br className="visible-md" />
              And if you don't like what I share you can always opt-out.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CompanyLogos = [Spotify, Slack, Spotify, Slack]

// Return component with companies
const TrustedBy = () => {
  // Return a component of companies logos
  const Companies = ({ companies }) => {
    return companies.map((Logo, index) => (
      <li className="mx-4 mb-md-5" key={index}>
        <Logo className="bg-primary-3 icon icon-md opacity-20" alt="icon" />
      </li>
    ))
  }
  return (
    <section className="pt-0">
      <div className="container">
        <div className="row text-center mb-5">
          <div className="col">
            <small className="text-uppercase">Trusted by companies like</small>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ul className="d-flex flex-wrap justify-content-center list-unstyled">
              <Companies companies={CompanyLogos} />
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

const UsedBy = () => {
  // Return a component of companies logos
  // const Companies = ({ companies }) => {
  //   return companies.map((Logo, index) => (
  //     <li className="mr-4 mb-5" key={index}>
  //       <Logo className="bg-dark icon icon-logo icon-md" />
  //     </li>
  //   ))
  // }
  return (
    <section className="pb-0">
      <div className="container">
        <div className="row justify-content-center text-center mb-6">
          {/* <div className="col-md-6 mb-3 mb-md-0"> */}
          <div className="col-xl-8 col-lg-8 col-md-10">
            <h2 className="h1">
              Bridging the gap between "Hello world" and{" "}
              <span className="highlight-text">real-world applications</span>.
            </h2>
            <p className="lead">
              Writing software is hard, architecting complex system is even
              harder and "Hello World" examples will only get you so far.
            </p>
            <p className="lead">
              That's why I have a really practical approach with what I teach,
              using examples drawn directly from the systems I have built.
            </p>
          </div>
          {/* <div className="col-md-5">
            <small className="text-uppercase mb-4">Trusted By</small>
            <ul className="d-flex flex-wrap list-unstyled">
              <Companies companies={CompanyLogos} />
            </ul>
          </div> */}
        </div>
      </div>
    </section>
  )
}

const CtaCourse = ({title, to}) => {
  return (
    <div className="row mb-5">
      <div className="col">
        <div className="card card-body border-0 o-hidden mb-0 bg-primary text-light">
          <div className="position-relative d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div className="h5 mb-md-0" dangerouslySetInnerHTML={{ __html: title }}>
            </div>
            <Link to={to} className="btn btn-lg btn-white">
              Check the course
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  InvalidEmailMessage,
  SuccessForm,
  FailureForm,
  Subscribe,
  UsedBy,
  TrustedBy,
  CtaCourse,
}
